export const environment = {
  agm: {
    apiKey: 'AIzaSyCj9fLIBRyCillM1UHmlKGrFkHiNoitVQQ',
    language: 'en',
    libraries: ['places']
  },
  algolia: {
    config: {
      au: {
        appId: 'I4JVWV9UAB'
      },
      eu: {
        appId: ''
      },
      us: {
        appId: 'QUUZ1FM10J'
      }
    } as { [key: string]: { appId: string } }
  },
  api: {
    publicApikey: 'jUiG8230wSsxSc3EjqOzKTFXvTeWKk9m',
    url: 'https://api.roxabo.com'
  },
  appUrls: {
    service: 'https://roxservice.roxabo.com'
  },
  authConfig: {
    clientID: 'qFRABP3ArQfCpf97XExDvTGsxcFWoJkL',
    domain: 'auth.roxabo.com'
  },
  cookies: {
    domain: 'roxabo.com'
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyAKO2MjP0wxS-z13VRxwtojRDkyHaISSs4',
      appId: '1:866878681242:web:16a4bc40126382f1532f23',
      authDomain: 'remservice-b8ee7.firebaseapp.com',
      measurementId: 'G-KEH5Y6J8Y9',
      messagingSenderId: '866878681242',
      projectId: 'remservice-b8ee7',
      storageBucket: 'remservice-b8ee7.appspot.com'
    }
  },
  production: true,
  stripe: {
    key: 'pk_test_51HPcgLJcKED04WHAWN1ZNFEQvZRRkyLBE4D46cE8ayoda4qUiBkpEzq4zKBTnIDITIwsx5SrRzoKrDucTahNuskG00BktMwD4D'
  }
};
